exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-search-results-tsx": () => import("./../../../src/pages/search-results.tsx" /* webpackChunkName: "component---src-pages-search-results-tsx" */),
  "component---src-templates-article-template-tsx": () => import("./../../../src/templates/articleTemplate.tsx" /* webpackChunkName: "component---src-templates-article-template-tsx" */),
  "component---src-templates-brand-template-tsx": () => import("./../../../src/templates/brandTemplate.tsx" /* webpackChunkName: "component---src-templates-brand-template-tsx" */),
  "component---src-templates-careers-template-tsx": () => import("./../../../src/templates/careersTemplate.tsx" /* webpackChunkName: "component---src-templates-careers-template-tsx" */),
  "component---src-templates-news-landing-template-tsx": () => import("./../../../src/templates/newsLandingTemplate.tsx" /* webpackChunkName: "component---src-templates-news-landing-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/pageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-range-template-tsx": () => import("./../../../src/templates/rangeTemplate.tsx" /* webpackChunkName: "component---src-templates-range-template-tsx" */),
  "component---src-templates-where-to-buy-template-tsx": () => import("./../../../src/templates/whereToBuyTemplate.tsx" /* webpackChunkName: "component---src-templates-where-to-buy-template-tsx" */)
}

